@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';






























































































































































































































































































































































































































.page-contacto {
  padding-bottom: 60px;

  .main-container {
    padding-top: 50px;

    h1 {
      margin: 0 0 20px 0;
    }

    p {
      color: #000;
      font-size: 16px;
      line-height: 28px;
      font-family: SuzukiPRORegular;

      b {
        font-weight: 600;
      }
    }
  }

  h2 {
    color: #000;
    font-weight: 600;
    text-align: left;
    margin: 0 0 40px 0;
    font-size: 24px;
    line-height: 24px;
    font-family: SuzukiPRORegular;

    &::after {
      content: '';
      display: block;
      height: 3px;
      width: 60px;
      background-color: $primary;
      margin-top: 16px;
    }
  }

  h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: SuzukiPRORegular;
  }

  .row {
    + .row {
      margin-top: 10px;
    }
  }

  label {
    color: #0f2832;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    font-weight: 600;
    font-family: 'Montserrat';
  }

  a:link,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
}

.card-confirm {
  border-radius: 5px;
}
